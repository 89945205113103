<template>
  <modal :name="modalName" height="auto" width="900" :scrollable="true" :clickToClose="false">
    <CloseModal :close="closeModal" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab title="Nova transferência">
            <div class="alert alert-danger" v-if="errors.length > 0">
              <div v-for="error in errors">
                {{ error }}
              </div>
            </div>
            <div class="form-group">
              <label>Colaborador origem ( de onde irá ser retirado o valor )</label>
              <SelectModelPicker
                identificador="modal-origem"
                campo-codigo-exibicao="id"
                campo-titulo-exibicao="nome"
                campo-subtitulo-exibicao="tipo"
                campo-extra-exibicao-label="Saldo"
                campo-extra-exibicao-valor="saldo_atual"
                v-model="colaboradorOrigem"
                url="/admin/conta-corrente"
                texto-clique-para-selecionar="Clique aqui para selecionar o usuário de origem"
                :campo-extra-middleware="representedAsMonetary"
              />
            </div>

            <div class="form-group">
              <label>Colaborador destino ( para onde irá o valor )</label>
              <SelectModelPicker
                identificador="modal-destino"
                campo-codigo-exibicao="id"
                campo-titulo-exibicao="nome"
                campo-subtitulo-exibicao="tipo"
                campo-extra-exibicao-label="Saldo"
                campo-extra-exibicao-valor="saldo_atual"
                v-model="colaboradorDestino"
                url="/admin/conta-corrente"
                texto-clique-para-selecionar="Clique aqui para selecionar o usuário de destino"
                :campo-extra-middleware="representedAsMonetary"
              />
            </div>

            <div class="form-group">
              <label>Valor a ser transferido</label>
              <InputMoney v-model="valorTransferencia" />
            </div>

            <div class="form-group">
              <label>Motivo da transferência</label>
              <input type="text" class="form-control" v-model="motivoTransferencia" />
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" v-on:click="submit()" class="btn btn-primary">
        Realizar transferência
      </button>
      <button type="button" class="btn btn-secondary" v-on:click="closeModal">
        Fechar
      </button>
    </div>
  </modal>
</template>
<script>
import CloseModal from "../../components/CloseModal";
import InputMoney from "@/components/InputMoney";
import { toMonetary } from "@/helpers/utils";
import {post} from "@/helpers/apiRequest";
import {alertOnResponseError, alertOnResponseSuccess} from "@/helpers/crud";

export default {
  name: "nomeDoComponenteDeModal",
  props: [
    "close",
  ],
  components: {InputMoney, CloseModal },
  data() {
    return {
      modalName: 'ModalTransferenciaContaCorrente',
      colaboradorOrigem: null,
      colaboradorDestino: null,
      valorTransferencia: 0,
      motivoTransferencia: null,
      errors: [],
    }
  },
  methods: {
    representedAsMonetary(row) {
      return toMonetary(row.saldo_atual);
    },
    closeModal() {
      this.$modal.hide(this.modalName);
    },
    resetDados() {
      this.colaboradorOrigem = null;
      this.colaboradorDestino = null;
      this.valorTransferencia = 0;
      this.motivoTransferencia = null;
      this.errors = [];
    },
    submit() {
      this.errors = [];

      if (!this.colaboradorOrigem) {
        this.errors.push('Selecione o colaborador de origem')
      }

      if (!this.colaboradorDestino) {
        this.errors.push('Selecione o colaborador de destino')
      }

      if (this.valorTransferencia <= 0) {
        this.errors.push('Valor da transferência deve ser maior que R$ 0,00')
      }

      if (this.colaboradorOrigem && this.valorTransferencia > this.colaboradorOrigem.saldo_atual) {
        this.errors.push('Valor da transferência deve ser inferior ao saldo atual do colaborador de origem')
      }

      if (!this.motivoTransferencia) {
        this.errors.push('Motivo da transferência é obrigatório')
      }

      if (!!this.motivoTransferencia && (this.motivoTransferencia.trim().length < 15 || this.motivoTransferencia.trim().length > 250)) {
        this.errors.push('Motivo da transferência é obrigatório de deve ter pelo entre 15 e 250 caracteres')
      }

      if (this.errors.length <= 0) {
        post('/admin/conta-corrente/transferir-saldo-colaborador', {
          idColaboradorOrigem   : this.colaboradorOrigem.id,
          idColaboradorDestino  : this.colaboradorDestino.id,
          valor                 : this.valorTransferencia,
          observacao            : this.motivoTransferencia,
        }).then((response) => {
          alertOnResponseSuccess(response);
          this.resetDados();
          this.close();
        })
          .catch(alertOnResponseError)
      }
    },
  },
};
</script>

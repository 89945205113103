<template>
  <div>
    <Extract
      :item="currentItem"
      :close="closeExtract"
    />
    <TransferenciaContaCorrente
      :close="closeTransferencia"
    />
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab title="Contas correntes">
        <crud-base
          :url="url"
          :columns="columns"
          :onUpdate="() => {}"
          :enableSearch="true"
          :formInputs="formInputs"
          :checkAlerts="false"
          :reload="reloadKey"
          primaryKey="id"
        >
          <template v-slot:header-buttons>
            <CButton @click="openTransferencia()" color="primary" size="sm">
              <fa-icon icon="clipboard-list"/> Nova transferência
            </CButton>
          </template>
          <template v-slot:action-buttons="{model}">
            <CButton @click="openExtract(model)" color="primary" size="sm">
              <fa-icon icon="clipboard-list"/>
            </CButton>
          </template>
        </crud-base>
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import CrudBase from "../../components/crud/CrudBase";
import {
  schemaMoney,
  schemaReadOnly,
  schemaTextField
} from "@/helpers/form";
import {toMonetary} from "@/helpers/utils";
import Extract from './Extract';
import clone from "just-clone";
import TransferenciaContaCorrente from "./TransferenciaContaCorrente";
import {uuid} from "vue-uuid";

export default {
  name: "crud-template",
  components: {
    CrudBase,
    Extract,
    TransferenciaContaCorrente
  },
  data() {
    return {
      url: "/admin/conta-corrente",
      columns: [
        { label: 'ID',    field: 'colaborador_id',  align: 'center', sortable: false },
        { label: 'Nome',  field: 'nome',            align: 'center', sortable: false },
        { label: 'Tipo',  field: 'tipo',            align: 'center', sortable: false },
        { label: 'Saldo', field: 'saldo_atual',     align: 'center', sortable: false, representedAs: (row) => toMonetary(row.saldo_atual) },
      ],
      formInputs: {
        id: schemaReadOnly('Código usuário', 2),
        nome: schemaReadOnly('Nome', 6),
        tipo: schemaReadOnly('Tipo de usuário', 4),
        observacao: schemaTextField('Motivo da alteração', true, 8),
        saldo_atual: schemaMoney('Novo Saldo', true, 4),
      },
      currentItem: {},
      reloadKey: null,
    };
  },
  methods: {
    openExtract(item) {
      this.currentItem = clone(item);
      this.$modal.show('cExtract');
    },
    closeExtract() {
      this.$modal.hide('cExtract');
    },
    openTransferencia() {
      this.$modal.show('ModalTransferenciaContaCorrente');
    },
    closeTransferencia() {
      this.$modal.hide('ModalTransferenciaContaCorrente');
      this.reloadKey = uuid.v4();
    }
  }
};
</script>
